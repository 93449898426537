<template>
  <div class="max-w-screen-sm mx-auto px-4 py-10">
    <form
      class="p-8 flex flex-col bg-light-grey rounded-md shadow-lg text-left"
      @submit.prevent="handlePasswordReset"
    >
      <h1 class="text-3xl text-at-light-green mb-4">Reset password</h1>
      <div class="flex flex-col mb-2">
        <label for="email" class="mb-1 text-sm text-at-light-green"
          >E-Mail</label
        >
        <input
          id="email"
          v-model="email"
          type="email"
          required
          class="p-2 text-gray-500 border-2 border-gray-300 focus:outline-none"
        />
      </div>

      <div v-if="statusMsg" class="mt-5 mb-5 p-4 rounded-md bg-gray-100">
        <p class="text-green-500">{{ statusMsg }}</p>
      </div>
      <div v-if="errorMsg" class="mt-5 mb-5 p-4 rounded-md bg-gray-100">
        <p class="text-red-500">{{ errorMsg }}</p>
      </div>

      <button
        type="submit"
        class="
          mt-6
          py-2
          px-6
          rounded-sm
          self-start
          text-sm text-white
          bg-black
          duration-300
          border-0 border-transparent
          hover:border-at-light-green hover:bg-gray-500
        "
      >
        Reset
      </button>

      <router-link class="text-sm mt-6 text-center" :to="{ name: 'Register' }"
        >Don't have an account?
        <span class="text-at-light-green">Register</span></router-link
      >
      <router-link class="text-sm mt-6 text-center" :to="{ name: 'Home' }"
        >Login</router-link
      >
    </form>
  </div>
</template>

<script setup>
import useAuthUser from '@/use/useAuthUser'
import { ref } from 'vue'

const { sendPasswordResetEmail } = useAuthUser()

const email = ref('')
const statusMsg = ref(null)
const errorMsg = ref(null)

const handlePasswordReset = async () => {
  try {
    await sendPasswordResetEmail(email.value)
    statusMsg.value = `Password reset email sent to: ${email.value}`
    setTimeout(() => {
      statusMsg.value = null
    }, 5000)
  } catch (error) {
    errorMsg.value = `Error: ${error.message}`
    setTimeout(() => {
      errorMsg.value = null
    }, 5000)
  }
}
</script>
